import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {useDateFieldState} from 'react-stately';
import {CalendarDateTime, createCalendar} from '@internationalized/date';
import {useDateField, useDateSegment} from '@react-aria/datepicker';
import {useLocale} from '@react-aria/i18n';
import {IconX} from '@tabler/icons-react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import type {AriaDateFieldProps, DateValue} from '@react-aria/datepicker';
import type {ReactNode} from 'react';
import type {DateFieldState, DateSegment} from 'react-stately';
import {FormControlContext} from '../form-control';
import IconButton from '../icon-button';
import {textFieldInputStyles, textFieldStyles} from '../text-field/styles';
import {dateFieldSegmentStyles} from './styles';
import type {TextFieldVariantProps} from '../text-field/styles';

dayjs.extend(utc);

export type DateSegmentProps = {
	segment: DateSegment;
	state: DateFieldState;
};

const DateFieldSegment = ({segment, state}: DateSegmentProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const {segmentProps} = useDateSegment(segment, state, ref);

	return (
		<div
			{...segmentProps}
			className={dateFieldSegmentStyles({placeholder: segment.isPlaceholder, editable: true})}
			ref={ref}
		>
			{segment.text}
		</div>
	);
};

export type DateFieldProps = Omit<
	AriaDateFieldProps<DateValue>,
	'isDisabled' | 'isReadOnly' | 'isRequired' | 'onChange' | 'value' | 'label' | 'isOpen'
> &
	Omit<TextFieldVariantProps, 'textarea' | 'invalid'> & {
		className?: string;
		prefix?: ReactNode;
		suffix?: ReactNode;
		required?: boolean;
		clearable?: boolean;
		value?: DateValue | null;
		open?: boolean;
		onChange?: (value: DateValue | null) => void;
		format?: string;
		placeholder?: string;
	};

const DateField = assign(
	forwardRef<HTMLInputElement, DateFieldProps>(
		(
			{
				size,
				subtle,
				hidden,
				open,
				disabled,
				readonly,
				required,
				prefix,
				clearable,
				suffix,
				className,
				...props
			},
			forwardedRef,
		) => {
			const formControlProps = useContext(FormControlContext);
			const {locale} = useLocale();
			const ref = useRef<HTMLInputElement>(null);
			const state = useDateFieldState({
				...props,
				locale,
				createCalendar,
				value:
					props.granularity === 'day'
						? props.value
						: props.value
							? new CalendarDateTime(
									props.value.year,
									props.value.month,
									props.value.day,
									'hour' in props.value ? props.value.hour : 0,
									'minute' in props.value ? props.value.minute : 0,
									'second' in props.value ? props.value.second : 0,
								)
							: null,
			});

			const {fieldProps} = useDateField(
				{
					...props,
					isOpen: open,
					isDisabled: !!disabled,
					isReadOnly: !!readonly,
					isRequired: required,
					'aria-label': formControlProps.label,
					'aria-labelledby': formControlProps.id,
					id: `${formControlProps.id}-field`,
				},
				state,
				ref,
			);

			useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement);

			return (
				<div
					className={twMerge(
						textFieldStyles({
							size,
							subtle,
							hidden,
							invalid: formControlProps.invalid,
							textarea: false,
							disabled: disabled,
							readonly: readonly,
						}),
						'[&&]:overflow-visible',
						className,
					)}
					ref={ref}
				>
					{prefix && <div className="flex shrink-0 flex-nowrap items-center">{prefix}</div>}
					<div
						{...fieldProps}
						aria-labelledby={formControlProps.id}
						className={twMerge(textFieldInputStyles(), 'flex items-center gap-1 [&&]:overflow-visible')}
					>
						{state.segments.map((segment, index) => (
							<DateFieldSegment key={index} segment={segment} state={state} />
						))}
					</div>
					{clearable && !disabled && !readonly && !!props.value && (
						<IconButton as="div" circular icon={IconX} onPress={() => props.onChange?.(null)} size="xs" />
					)}
					{suffix && <div className="flex shrink-0 flex-nowrap items-center">{suffix}</div>}
				</div>
			);
		},
	),
	{Segment: DateFieldSegment},
);

DateField.displayName = 'DateField';

export default DateField;

export * from './styles';
export type {DateValue} from '@internationalized/date';
