import {z} from 'zod';

import type {Company} from '@job-ish/database/types';

export const upsertCompanySchema = z.object({
	name: z.string().min(1, {message: 'Name is required'}),
	website: z
		.string()
		.regex(/^(https?:\/\/)?([\d.a-z-]+)\.([.a-z]{2,6})([\w ./-]*)*\/?$/, {
			message: 'Website must be a valid URL',
		})
		.optional()
		.transform(value => {
			if (!value) return;
			return value.includes('http') || value.includes('https') ? value : `https://${value}`;
		}),
});

export type UpsertCompanyFormSchema = z.infer<typeof upsertCompanySchema>;

export const getDefaultUpsertCompanyFormValues = (company?: Company) => ({
	name: company?.name ?? '',
	website: company?.website ?? undefined,
});
