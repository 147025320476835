'use client';

import {fetchCompanies} from '~/shared/api/company';
import {QUERY_KEYS} from '~/shared/constants/keys';
import {useSupabase} from '~/shared/hooks/use-supabase';
import {useToast} from '@job-ish/ui/hooks';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

import type {Company} from '@job-ish/database/types';
import type {SetRequired} from 'type-fest';

export const useCompanies = () => {
	const {supabase} = useSupabase();
	return useQuery({
		queryKey: QUERY_KEYS.Companies,
		queryFn: async () => {
			const {data} = await fetchCompanies(supabase);
			return data ?? [];
		},
	});
};

export const useUpsertCompany = () => {
	const queryClient = useQueryClient();
	const {supabase} = useSupabase();

	const {show: showErrorToast} = useToast({
		accent: 'danger',
		accentPosition: 'left',
		description: 'Company could not be updated. Please try again.',
		duration: 1500,
		title: 'Company Update Failed',
	});

	return useMutation({
		mutationFn: async (data: SetRequired<Partial<Company>, 'name'>) => {
			const {data: existingCompany} = await supabase
				.from('companies')
				.select('id')
				.match({name: data.name})
				.maybeSingle<Company>();

			const {data: company, error} = await supabase
				.from('companies')
				.upsert(existingCompany ? {...existingCompany, ...data} : data, {onConflict: 'id'})
				.select('id')
				.maybeSingle<Company>();
			if (error) throw error;
			return company;
		},
		onMutate: async (data: SetRequired<Partial<Company>, 'name'>) => {
			await queryClient.cancelQueries({queryKey: QUERY_KEYS.Companies});
			const previousCompanies = queryClient.getQueryData<Company[]>(QUERY_KEYS.Companies) ?? [];

			const company = previousCompanies.find(company => company.id === data.id);
			const updatedCompanies = company
				? previousCompanies.map(company => (company.id === data.id ? {...company, ...data} : company))
				: [...previousCompanies, {...data, id: -1} as Company];

			queryClient.setQueryData(QUERY_KEYS.Companies, updatedCompanies);
			return previousCompanies;
		},
		onError: (_error, {id}, context) => {
			queryClient.setQueryData(QUERY_KEYS.Companies, context);
			showErrorToast(
				id
					? undefined
					: {title: 'Company Creation Failed', description: 'Company could not be added. Please try again.'},
			);
		},
		onSettled: async () => {
			await queryClient.invalidateQueries({queryKey: QUERY_KEYS.Companies});
			await queryClient.invalidateQueries({queryKey: QUERY_KEYS.UnfilteredJobs});
			await queryClient.invalidateQueries({queryKey: QUERY_KEYS.Jobs});
		},
	});
};

export const useDeleteCompany = () => {
	const queryClient = useQueryClient();
	const {supabase} = useSupabase();

	const {show: showErrorToast} = useToast({
		accent: 'danger',
		accentPosition: 'left',
		description: 'Company could not be deleted. Please try again.',
		duration: 1500,
		title: 'Company Deletion Failed',
	});

	return useMutation({
		mutationFn: async ({id}: {id: number}) => {
			const {data, error} = await supabase.from('companies').delete().match({id}).maybeSingle<Company>();
			if (error) throw error;
			return data;
		},
		onMutate: async ({id}: {id: number}) => {
			await queryClient.cancelQueries({queryKey: QUERY_KEYS.Companies});
			const previousCompanies = queryClient.getQueryData<Company[]>(QUERY_KEYS.Companies) ?? [];

			queryClient.setQueryData(
				QUERY_KEYS.Companies,
				previousCompanies.filter(company => company.id !== id),
			);

			return previousCompanies;
		},
		onError: (_error, _variables, context) => {
			queryClient.setQueryData(QUERY_KEYS.Companies, context);
			showErrorToast();
		},
		onSettled: async () => {
			await queryClient.invalidateQueries({queryKey: QUERY_KEYS.Companies});
			await queryClient.invalidateQueries({queryKey: QUERY_KEYS.UnfilteredJobs});
			await queryClient.invalidateQueries({queryKey: QUERY_KEYS.Jobs});
		},
	});
};
