import {forwardRef, useContext, useId} from 'react';
import * as RadioGroupPrimitives from '@radix-ui/react-radio-group';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import {FormControlContext} from '../form-control';
import {radioGroupItemStyles, radioGroupStyles} from './styles';
import type {RadioGroupItemVariantProps, RadioGroupVariantProps} from './styles';

export type RadioGroupItemProps = Omit<RadioGroupPrimitives.RadioGroupItemProps, 'children'> &
	Omit<RadioGroupItemVariantProps, 'invalid'>;
const RadioGroupItem = forwardRef<HTMLButtonElement, RadioGroupItemProps>(
	({disabled, className, ...props}, forwardedRef) => {
		const formControlProps = useContext(FormControlContext);

		return (
			<RadioGroupPrimitives.Item
				{...props}
				className={twMerge(radioGroupItemStyles({disabled, invalid: formControlProps.invalid}), className)}
				disabled={disabled}
				ref={forwardedRef}
			>
				<RadioGroupPrimitives.Indicator className="rounded-full p-1.5 transition-colors ease-in-out data-[state=checked]:bg-violet9 data-[state=checked]:text-mauve1" />
			</RadioGroupPrimitives.Item>
		);
	},
);

export type RadioGroupProps = RadioGroupPrimitives.RadioGroupProps & Omit<RadioGroupVariantProps, 'invalid'>;
const RadioGroup = assign(
	forwardRef<HTMLDivElement, RadioGroupProps>(
		({readonly, orientation, size, disabled, className, id, ...props}, forwardedRef) => {
			const formControlProps = useContext(FormControlContext);
			const defaultId = useId();

			return (
				<RadioGroupPrimitives.Root
					{...props}
					aria-labelledby={formControlProps.id}
					className={twMerge(radioGroupStyles({orientation, readonly, size, disabled}), className)}
					disabled={disabled}
					id={id || `${formControlProps.id || defaultId}-field`}
					orientation={orientation}
					ref={forwardedRef}
				/>
			);
		},
	),
	{Item: RadioGroupItem},
);

RadioGroup.displayName = 'RadioGroup';
RadioGroup.Item.displayName = 'RadioGroup.Item';

export default RadioGroup;

export * from './styles';
