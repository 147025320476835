import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const radioGroupStyles = cva('flex select-none', {
	variants: {
		orientation: {horizontal: 'flex flex-row items-center gap-3', vertical: 'flex-col gap-1'},
		disabled: {true: 'opacity-40 pointer-events-none'},
		readonly: {true: 'opacity-70 pointer-events-none'},
		size: {sm: 'py-2 text-xs', md: 'py-2.5 text-base', lg: 'py-3 text-base'},
	},
	defaultVariants: {orientation: 'vertical', disabled: false, readonly: false, size: 'md'},
});

export type RadioGroupVariantProps = VariantProps<typeof radioGroupStyles>;

export const radioGroupItemStyles = cva(
	[
		'bg-mauve1 dark:bg-mauve12 h-5 w-5 rounded-full border border-mauve7 flex items-center justify-center transition-colors ease-in-out',
		'focus-visible:outline',
	],
	{
		variants: {
			disabled: {true: 'opacity-40 pointer-events-none'},
			invalid: {
				true: 'border-red7 hover:border-red8 ring ring-red7 focus-visible:outline-red8',
				false: 'focus-visible:outline-mauve8',
			},
		},
	},
);

export type RadioGroupItemVariantProps = VariantProps<typeof radioGroupItemStyles>;
