import {create} from 'zustand';

export type CoreStoreData = {
	loading: boolean;
};

export type CoreStoreState = CoreStoreData & {
	setLoading: (loading: CoreStoreData['loading']) => void;
};

export const useCoreStore = create<CoreStoreState>(set => ({
	loading: false,
	setLoading: loading => set({loading}),
}));
