import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {useTimeFieldState} from 'react-stately';
import {useTimeField} from '@react-aria/datepicker';
import {useLocale} from '@react-aria/i18n';
import {IconX} from '@tabler/icons-react';
import {twMerge} from 'tailwind-merge';

import type {AriaTimeFieldProps, TimeValue} from '@react-aria/datepicker';
import type {ReactNode} from 'react';
import DateField from '../date-field';
import {FormControlContext} from '../form-control';
import IconButton from '../icon-button';
import {textFieldInputStyles, textFieldStyles} from '../text-field/styles';
import type {TextFieldVariantProps} from '../text-field/styles';

export type TimeFieldProps = Omit<
	AriaTimeFieldProps<TimeValue>,
	'isDisabled' | 'isReadOnly' | 'isRequired' | 'onChange' | 'value'
> &
	Omit<TextFieldVariantProps, 'textarea' | 'invalid'> & {
		className?: string;
		prefix?: ReactNode;
		suffix?: ReactNode;
		required?: boolean;
		clearable?: boolean;
		value?: TimeValue | null;
		onChange?: (value: TimeValue | null) => void;
	};

const TimeField = forwardRef<HTMLInputElement, TimeFieldProps>(
	(
		{size, subtle, hidden, disabled, readonly, required, clearable, prefix, suffix, className, ...props},
		forwardedRef,
	) => {
		const formControlProps = useContext(FormControlContext);
		const ref = useRef<HTMLInputElement>(null);
		const {locale} = useLocale();
		const state = useTimeFieldState({
			...props,
			isDisabled: !!disabled,
			isReadOnly: !!readonly,
			isRequired: required,
			locale,
		});

		const {fieldProps} = useTimeField(
			{...props, 'aria-label': formControlProps.label, 'aria-labelledby': formControlProps.id},
			state,
			ref,
		);

		useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement);

		return (
			<div
				className={twMerge(
					textFieldStyles({
						size,
						subtle,
						hidden,
						invalid: formControlProps.invalid,
						textarea: false,
						disabled,
						readonly,
					}),
					'[&&]:overflow-visible',
					className,
				)}
			>
				{prefix && <div className="flex shrink-0 flex-nowrap items-center">{prefix}</div>}
				<div
					{...fieldProps}
					aria-labelledby={formControlProps.id}
					className={twMerge(textFieldInputStyles(), 'flex items-center gap-1 [&&]:overflow-visible')}
					ref={ref}
				>
					{state.segments.map((segment, i) => (
						<DateField.Segment key={i} segment={segment} state={state} />
					))}
				</div>
				{clearable && !disabled && !readonly && !!props.value && (
					<IconButton as="div" circular icon={IconX} onPress={() => props.onChange?.(null)} size="xs" />
				)}
				{suffix && <div className="flex shrink-0 flex-nowrap items-center">{suffix}</div>}
			</div>
		);
	},
);

TimeField.displayName = 'TimeField';

export default TimeField;
