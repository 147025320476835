import {z} from 'zod';

import type {Job} from '@job-ish/database/types';

export const upsertCoverLetterSchema = z.object({
	job_title: z.string().min(1, {message: 'Job Title is required'}),
	company: z.number({required_error: 'Company is required', invalid_type_error: 'Company is required'}),
	address_to: z.string().min(1, {message: 'Address To is required'}),
	additional_details: z
		.string()
		.max(150, {message: 'Additional Details must be less than 150 characters'})
		.optional(),
	resume_id: z.number().nullable().optional(),
	job_description: z.string().nullable().optional(),
});

export type UpsertCoverLetterFormSchema = z.infer<typeof upsertCoverLetterSchema>;

export const getDefaultUpsertCoverLetterFormValues = ({
	title,
	company,
	additionalDetails,
	resume,
	job_description,
}: Pick<Job, 'title' | 'company' | 'job_description' | 'resume'> & {
	additionalDetails?: string;
	addressTo?: string;
}) => ({
	job_title: title,
	company: company?.id ?? undefined,
	additional_details: additionalDetails ?? '',
	address_to: company?.name ? `${company.name} Hiring Team` : 'Hiring Team',
	resume_id: resume,
	job_description: job_description,
});
