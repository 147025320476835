'use client';

import {useCallback, useEffect, useState} from 'react';
import {UpsertJobForm} from '~/shared/components/forms/job/upsert';
import {useUpsertJob} from '~/shared/data/job';
import type {UpsertJobFormSchema} from '~/shared/schemas/job';
import {Sheet, TextButton, Toast, ToggleButton} from '@job-ish/ui/components';
import {useToast} from '@job-ish/ui/hooks';
import {IconHeart} from '@tabler/icons-react';
import NextLink from 'next/link';

import type {Company, Job} from '@job-ish/database/types';
import type {ReactNode} from 'react';
import type {SubmitHandler} from 'react-hook-form';

export type JobSheetProps = {
	job?: Job;
	trigger?: ReactNode;
	defaultOpen?: boolean;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
	dismissable?: boolean;
	initialValues?: Omit<Partial<Job>, 'company'> & {
		company?: Partial<Company>;
	};
	defaultTab?: string;
};

export const JobSheet = ({
	job,
	trigger,
	defaultOpen,
	open,
	onOpenChange,
	dismissable,
	initialValues,
	defaultTab,
}: JobSheetProps) => {
	const {mutate: upsertJob} = useUpsertJob();
	const [favorite, setFavorite] = useState(job?.is_favorite ?? false);
	const [ephemeralJob, setEphemeralJob] = useState<Job | undefined>(job);

	const {show: showSuccessToast, remove: hideToast} = useToast({
		title: 'Application Added',
		description: 'The application has been successfully added to your dashboard.',
		accent: 'success',
		accentPosition: 'left',
		duration: 2500,
	});

	useEffect(() => {
		if (job) {
			setEphemeralJob(job);
		}
	}, [job]);

	const handleJobUpsert: SubmitHandler<UpsertJobFormSchema> = useCallback(
		formData => {
			upsertJob(
				{...formData, id: job?.id || ephemeralJob?.id},
				{
					onSuccess: data => {
						if (data) {
							setEphemeralJob(data);
						}

						if (!job?.id) {
							showSuccessToast({
								children: (
									<>
										<Toast.Action altText="Go to dashboard" className="mt-auto">
											<TextButton as={NextLink} href="/dashboard" onPress={hideToast} size="sm">
												Go to Dashboard
											</TextButton>
										</Toast.Action>
									</>
								),
							});
						}
					},
				},
			);
			if (!job) {
				onOpenChange?.(false);
			}
		},
		[ephemeralJob?.id, hideToast, job, onOpenChange, showSuccessToast, upsertJob],
	);

	const handleFavorite = useCallback(() => {
		if (job) {
			setFavorite(!favorite);
			upsertJob({...job, is_favorite: !job.is_favorite, company: job.company?.id});
		}
	}, [favorite, job, upsertJob]);

	return (
		<Sheet defaultOpen={defaultOpen} modal onOpenChange={onOpenChange} open={open}>
			<Sheet.Trigger asChild>{trigger}</Sheet.Trigger>
			<Sheet.Content blur className="z-50" dismissable={dismissable} side="right">
				<Sheet.Title className="flex items-center gap-2">
					<span>{job ? 'Update' : 'Add'} Application</span>
					{job && (
						<ToggleButton
							circular
							color={favorite ? 'danger' : 'neutral'}
							icon={IconHeart}
							intent="ghost"
							onPress={handleFavorite}
							pressed={favorite}
							size="sm"
						/>
					)}
				</Sheet.Title>
				<UpsertJobForm
					defaultTab={defaultTab}
					initialValues={initialValues}
					job={ephemeralJob}
					onSubmit={handleJobUpsert}
				/>
			</Sheet.Content>
		</Sheet>
	);
};
