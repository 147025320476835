import {useMemo} from 'react';
import {useJobBoardFilter} from '~/app/(unauthenticated)/job-board/_/hooks/use-job-board-filter';
import {
	fetchJobBoardCities,
	fetchJobBoardCompanies,
	fetchJobBoardCompany,
	fetchJobBoardCountries,
	fetchJobBoardIndustries,
	fetchJobBoardJob,
	fetchJobBoardJobs,
	fetchJobBoardJobTypes,
	fetchJobBoardRegions,
} from '~/shared/api/job-board';
import {QUERY_KEYS} from '~/shared/constants/keys';
import type {JobBoardFilterConfig} from '~/shared/types/job-board-filter';
import {useQuery} from '@tanstack/react-query';

export const useJobBoardJobs = (persistedFilter: JobBoardFilterConfig) => {
	const {filter} = useJobBoardFilter();

	const mergedFilter = useMemo(
		() => ({...persistedFilter, page: filter.page, pageSize: filter.pageSize}),
		[persistedFilter, filter],
	);

	return useQuery({
		queryKey: [QUERY_KEYS.JobBoardJobs, mergedFilter],
		queryFn: async () => fetchJobBoardJobs(mergedFilter),
	});
};

export const useJobBoardJob = (id: string | string[] | null) =>
	useQuery({
		queryKey: [QUERY_KEYS.JobBoardJob, id],
		queryFn: async () => {
			if (Array.isArray(id)) return null;
			return await fetchJobBoardJob(id);
		},
	});

export const useJobBoardJobTypes = () =>
	useQuery({
		queryKey: QUERY_KEYS.JobBoardJobTypes,
		queryFn: fetchJobBoardJobTypes,
	});

export const useJobBoardCountries = () =>
	useQuery({
		queryKey: QUERY_KEYS.JobBoardCountries,
		queryFn: fetchJobBoardCountries,
	});

export const useJobBoardCities = (name?: string, countryCode?: string) =>
	useQuery({
		queryKey: [QUERY_KEYS.JobBoardCities, {name, countryCode}],
		queryFn: async () => await fetchJobBoardCities(name, countryCode),
	});

export const useJobBoardCompany = (id?: number) =>
	useQuery({
		queryKey: [QUERY_KEYS.JobBoardCompany, id],
		queryFn: async () => await fetchJobBoardCompany(id),
		enabled: !!id,
	});

export const useJobBoardCompanies = (name?: string | null) =>
	useQuery({
		queryKey: [QUERY_KEYS.JobBoardCompanies, name],
		queryFn: async () => await fetchJobBoardCompanies(name),
	});

export const useJobBoardIndustries = () =>
	useQuery({
		queryKey: QUERY_KEYS.JobBoardIndustries,
		queryFn: fetchJobBoardIndustries,
	});

export const useJobBoardRegions = () =>
	useQuery({
		queryKey: QUERY_KEYS.JobBoardRegions,
		queryFn: fetchJobBoardRegions,
	});
