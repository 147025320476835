import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const dateFieldSegmentStyles = cva('outline-mauve8', {
	variants: {
		placeholder: {true: 'opacity-80'},
		editable: {true: 'focus-visible:outline-2 focus-visible:outline'},
	},
	defaultVariants: {placeholder: false},
});

export type DateFieldSegmentVariantProps = VariantProps<typeof dateFieldSegmentStyles>;
