import {useEffect} from 'react';
import {DeleteCompanyForm} from '~/shared/components/forms/company/delete';
import {useDeleteCompany} from '~/shared/data/company';
import {AlertDialog} from '@job-ish/ui/components';

import type {Company} from '@job-ish/database/types';
import type {ReactNode} from 'react';

export type DeleteCompanyAlertProps = {
	company: Company;
	open: boolean;
	onOpenChange: (open: boolean) => void;
	trigger?: ReactNode;
	onDeleted?: () => void;
};

export const DeleteCompanyAlert = ({
	company,
	open,
	onOpenChange,
	onDeleted,
	trigger,
}: DeleteCompanyAlertProps) => {
	const {mutate: deleteCompany, isSuccess} = useDeleteCompany();

	const onSubmit = async () => {
		onOpenChange(false);
		deleteCompany({id: company.id});
	};

	useEffect(() => {
		if (isSuccess) {
			onDeleted?.();
		}
	}, [isSuccess, onDeleted]);

	return (
		<AlertDialog onOpenChange={onOpenChange} open={open}>
			<AlertDialog.Trigger asChild>{trigger}</AlertDialog.Trigger>
			<AlertDialog.Content className="md:w-[28rem]">
				<AlertDialog.Title>Delete Company</AlertDialog.Title>
				<AlertDialog.Description>
					Are you sure you want to delete <span className="font-bold">{company.name}</span>? This action
					cannot be undone.
				</AlertDialog.Description>
				<DeleteCompanyForm onCancel={() => onOpenChange(false)} onSubmit={onSubmit} />
			</AlertDialog.Content>
		</AlertDialog>
	);
};
