import {type ReactNode, useCallback} from 'react';
import type {UpsertCoverLetterFormProps} from '~/shared/components/forms/cover-letter';
import {UpsertCoverLetterForm} from '~/shared/components/forms/cover-letter';
import type {UpsertCoverLetterFormSchema} from '~/shared/schemas/cover-letter';
import {Dialog} from '@job-ish/ui/components';

import type {useCompletion} from 'ai/react';
import type {SubmitHandler} from 'react-hook-form';

export type CoverLetterDialogProps = {
	trigger?: ReactNode;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
	complete: (
		options: Parameters<ReturnType<typeof useCompletion>['complete']>[1],
		companyId?: number,
	) => void;
	id?: number;
} & Omit<UpsertCoverLetterFormProps, 'onSubmit'>;

export const CoverLetterDialog = ({
	trigger,
	open,
	onOpenChange,
	complete,
	id,
	...props
}: CoverLetterDialogProps) => {
	const handleCoverLetterUpsert: SubmitHandler<UpsertCoverLetterFormSchema> = useCallback(
		async formData => {
			complete({body: {id, ...formData}}, formData.company);
			onOpenChange?.(false);
		},
		[complete, onOpenChange, id],
	);

	return (
		<Dialog onOpenChange={onOpenChange} open={open}>
			<Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
			<Dialog.Content>
				<Dialog.Title>Generate Cover Letter</Dialog.Title>
				<UpsertCoverLetterForm onSubmit={handleCoverLetterUpsert} {...props} />
			</Dialog.Content>
		</Dialog>
	);
};
