import {useCallback, useMemo} from 'react';
import type {JobStatus, JobStatusGroup} from '~/shared/constants/job';
import {jobExits, jobStatusGroups} from '~/shared/constants/job';
import {getJobsForStatus, getJobsForStatusGroup, getJobStatusesForGroup} from '~/shared/utils/job';

import type {Job} from '@job-ish/database/types';

export const useJobStatuses = (jobs?: Job[]) => {
	const jobsForStatus = useCallback((status: JobStatus) => getJobsForStatus(jobs, status), [jobs]);

	const jobsForGroup = useCallback(
		(statusGroup: JobStatusGroup) => getJobsForStatusGroup(jobs, statusGroup),
		[jobs],
	);

	const statusesForGroup = useCallback(
		(statusGroup: JobStatusGroup) => getJobStatusesForGroup(statusGroup),
		[],
	);

	const exitsForGroup = useCallback(
		(group: JobStatusGroup) => Object.entries(jobExits).filter(([, status]) => status.group === group),
		[],
	);

	const filteredGroups = useMemo(
		() =>
			Object.entries(jobStatusGroups).filter(
				([statusGroupKey]) => jobsForGroup(statusGroupKey as JobStatusGroup).length > 0,
			),
		[jobsForGroup],
	);

	return {jobsForStatus, jobsForGroup, statusesForGroup, exitsForGroup, filteredGroups};
};
