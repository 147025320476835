import {forwardRef} from 'react';
import type {JobStatus, JobStatusGroup} from '~/shared/constants/job';
import {jobStatuses, jobStatusGroups} from '~/shared/constants/job';
import {useJobStatuses} from '~/shared/hooks/use-job-status';
import {Select} from '@job-ish/ui/components';
import {IconStatusChange} from '@tabler/icons-react';

import type {SelectTriggerProps} from '@job-ish/ui/components';

type JobStatusSelectProps = {
	value?: JobStatus;
	onValueChange: (value?: JobStatus) => void;
	subtle?: SelectTriggerProps['subtle'];
	size?: SelectTriggerProps['size'];
};

export const JobStatusSelect = forwardRef<HTMLButtonElement, JobStatusSelectProps>(
	({value, onValueChange, subtle, size}: JobStatusSelectProps, forwardedRef) => {
		const {statusesForGroup} = useJobStatuses();

		return (
			<Select onValueChange={value => onValueChange(value as JobStatus)} value={value}>
				<Select.Trigger
					className="w-full"
					placeholder="Select a status"
					prefix={<IconStatusChange className="h-4 w-4" />}
					ref={forwardedRef}
					size={size}
					subtle={subtle}
				>
					{value ? jobStatuses[value]?.label : undefined}
				</Select.Trigger>
				<Select.Content className="z-50 w-64">
					{Object.entries(jobStatusGroups).map(([groupKey, group], index) => (
						<Select.Group key={groupKey}>
							<Select.Label>{group.label}</Select.Label>
							{statusesForGroup(groupKey as JobStatusGroup).map(([statusKey, status]) => (
								<Select.Item key={statusKey} value={statusKey}>
									{status.label}
								</Select.Item>
							))}
							{index < Object.values(jobStatusGroups).length - 1 && <Select.Separator />}
						</Select.Group>
					))}
				</Select.Content>
			</Select>
		);
	},
);
