import type {JobExit, JobStatus, JobType} from '~/shared/constants/job';
import {JOB_EXIT, JOB_STATUS, JOB_TYPE} from '~/shared/constants/job';
import {z} from 'zod';

import type {Company, Job} from '@job-ish/database/types';

export const upsertJobSchema = z.object({
	title: z.string().min(1, {message: 'Job Title is required'}),
	status: z.enum(Object.values(JOB_STATUS) as [JobStatus, ...JobStatus[]], {
		required_error: 'Select an option',
		invalid_type_error: 'Select an option',
	}),
	exit_status: z
		.enum(Object.values(JOB_EXIT) as [JobExit, ...JobExit[]], {
			required_error: 'Select an option',
			invalid_type_error: 'Select an option',
		})
		.optional(),
	company: z.number().nullable().optional(),
	location: z.string().optional(),
	url: z.preprocess(
		arg => (arg === '' ? undefined : arg),
		z
			.string()
			.url({message: 'Listing URL must be a valid job listing URL'})
			.optional()
			.transform(url => (url ? decodeURIComponent(url) : url)),
	),
	min_salary: z.number().optional().nullable(),
	max_salary: z.number().optional().nullable(),
	remote: z.enum(['true', 'false', 'hybrid'], {
		required_error: 'Select an option',
		invalid_type_error: 'Select an option',
	}),
	type: z
		.enum(Object.values(JOB_TYPE) as [JobType, ...JobType[]])
		.optional()
		.nullable(),
	notes: z.string().optional(),
	cover_letter: z.string().optional(),
	labels: z.array(z.string()).optional(),
	min_hourly_rate: z.number().optional().nullable(),
	max_hourly_rate: z.number().optional().nullable(),
	resume: z.number().optional().nullable(),
	applied_at: z.string().optional().nullable(),
	job_description: z.string().optional(),
	currency: z.string().optional(),
	external_id: z.number().optional(),
});

export type UpsertJobFormSchema = z.infer<typeof upsertJobSchema>;

export const getDefaultUpsertJobFormValues = (
	job?: (Omit<Partial<Job>, 'company'> & {company?: Partial<Company>}) | Partial<Job>,
) => ({
	title: job?.title ?? '',
	company: job?.company?.id ?? undefined,
	location: job?.location ?? undefined,
	remote: job?.remote as UpsertJobFormSchema['remote'],
	url: job?.url ?? undefined,
	status: job?.status as UpsertJobFormSchema['status'],
	exit_status: (job?.exit_status as UpsertJobFormSchema['exit_status']) ?? undefined,
	min_hourly_rate: job?.min_hourly_rate ?? undefined,
	max_hourly_rate: job?.max_hourly_rate ?? undefined,
	min_salary: job?.min_salary ?? undefined,
	max_salary: job?.max_salary ?? undefined,
	type: (job?.type as UpsertJobFormSchema['type']) ?? undefined,
	labels: job?.labels ?? [],
	notes: job?.notes ?? undefined,
	cover_letter: job?.cover_letter ?? undefined,
	resume: job?.resume ?? undefined,
	applied_at: job?.applied_at ?? undefined,
	job_description: job?.job_description ?? undefined,
	currency: job?.currency ?? undefined,
	external_id: job?.external_id ?? undefined,
});
