import {CalendarDate, CalendarDateTime} from '@job-ish/ui/components';
import dayjs from 'dayjs';

import type {DateValue} from '@job-ish/ui/components';

export const convertToUnix = (dateValue: DateValue) =>
	'hour' in dateValue
		? dayjs(
				new Date(dateValue.year, dateValue.month - 1, dateValue.day, dateValue.hour, dateValue.minute),
			).unix()
		: dayjs(new Date(dateValue.year, dateValue.month - 1, dateValue.day)).unix();

export const convertToCalendarDateTime = (timestampz: string) =>
	new CalendarDateTime(
		dayjs(timestampz).year(),
		dayjs(timestampz).month() + 1,
		dayjs(timestampz).date(),
		dayjs(timestampz).hour(),
		dayjs(timestampz).minute(),
	);

export const convertToProperCalendarDateTime = (timestampz: string) =>
	convertToCalendarDateTime(dayjs(timestampz).toString());

export const convertToCalendarDate = (timestampz: string) =>
	new CalendarDate(dayjs(timestampz).year(), dayjs(timestampz).month(), dayjs(timestampz).date());
