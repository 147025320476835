'use client';

import {useCallback} from 'react';
import {fetchJob, fetchJobs} from '~/shared/api/job';
import {JOB_STATUS, type JobStatus} from '~/shared/constants/job';
import {QUERY_KEYS} from '~/shared/constants/keys';
import {useSupabase} from '~/shared/hooks/use-supabase';
import {useCoreStore} from '~/shared/stores/use-core-store';
import {getFilteredJobs} from '~/shared/utils/job';
import {useToast} from '@job-ish/ui/hooks';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

import type {Company, Job, JobSearch, Operation} from '@job-ish/database/types';
import type {SetRequired} from 'type-fest';

export const useJob = (job?: Partial<Job>) => {
	const {supabase} = useSupabase();

	return useQuery({
		queryKey: [QUERY_KEYS.Job, job?.id || job?.url],
		queryFn: async () => {
			if (!job) return;
			const {data} = await fetchJob(supabase, job);
			return data;
		},
		enabled: !!job,
	});
};

export const useUnfilteredJobs = () => {
	const {supabase} = useSupabase();
	return useQuery({
		queryKey: QUERY_KEYS.UnfilteredJobs,
		queryFn: async () => {
			const {data} = await fetchJobs(supabase);
			return data;
		},
	});
};

export const useJobs = () => {
	const queryClient = useQueryClient();
	const {setLoading, loading} = useCoreStore();

	const filterDataByActiveOperation = useCallback(
		(data: Job[]) => {
			setTimeout(() => {
				if (loading) setLoading(false);
			}, 500);

			const activeOperation = queryClient.getQueryData<Operation>(QUERY_KEYS.ActiveOperation);
			return getFilteredJobs(data, activeOperation?.filter, activeOperation?.search_text);
		},
		[loading, queryClient, setLoading],
	);

	return useQuery({
		queryKey: QUERY_KEYS.Jobs,
		queryFn: () => queryClient.getQueryData<Job[]>(QUERY_KEYS.UnfilteredJobs) ?? [],
		select: filterDataByActiveOperation,
	});
};

export const useUpdateJobIndex = () => {
	const queryClient = useQueryClient();
	const {supabase} = useSupabase();

	return useMutation({
		mutationFn: async ({
			id,
			index,
			updatedJobs,
		}: {
			id: number;
			index: number;
			updatedJobs: Omit<Job, 'company'>[];
		}) => {
			const {data} = await supabase.from('jobs').update({index}).match({id}).returns<Job[]>();

			if (updatedJobs.length > 0) {
				await supabase.from('jobs').upsert(updatedJobs);
			}

			return data;
		},

		onSettled: async () => {
			await queryClient.invalidateQueries({queryKey: QUERY_KEYS.UnfilteredJobs});
			await queryClient.invalidateQueries({queryKey: QUERY_KEYS.Jobs});
		},
	});
};

export const useUpdateJobStatus = () => {
	const queryClient = useQueryClient();
	const {supabase} = useSupabase();

	return useMutation({
		mutationFn: async ({id, status, index}: {id: number; status: JobStatus; index?: number | null}) => {
			const {data} = await supabase
				.from('jobs')
				.update({
					status,
					exit_status: null,
					index,
					applied_at: status === JOB_STATUS.ApplicationSubmitted ? new Date().toISOString() : undefined,
				})
				.match({id})
				.returns<Job[]>();
			return data;
		},
		onMutate: async ({id, status, index}) => {
			if (index === undefined) return;

			await queryClient.cancelQueries({queryKey: [QUERY_KEYS.Jobs, QUERY_KEYS.UnfilteredJobs]});
			const previousJobs = queryClient.getQueryData<Job[]>(QUERY_KEYS.Jobs) ?? [];
			const previousUnfilteredJobs = queryClient.getQueryData<Job[]>(QUERY_KEYS.UnfilteredJobs) ?? [];

			queryClient.setQueryData(
				QUERY_KEYS.Jobs,
				previousJobs.map(job => (job.id === id ? {...job, status, exit_status: null, index} : job)),
			);

			queryClient.setQueryData(
				QUERY_KEYS.UnfilteredJobs,
				previousUnfilteredJobs.map(job => (job.id === id ? {...job, status, exit_status: null, index} : job)),
			);

			return {previousJobs, previousUnfilteredJobs};
		},
		onError: (_error, _variables, context) => {
			queryClient.setQueryData(QUERY_KEYS.Jobs, context?.previousJobs);
			queryClient.setQueryData(QUERY_KEYS.UnfilteredJobs, context?.previousUnfilteredJobs);
		},
		onSettled: async () => {
			await queryClient.invalidateQueries({queryKey: QUERY_KEYS.UnfilteredJobs});
			await queryClient.invalidateQueries({queryKey: QUERY_KEYS.Jobs});
		},
	});
};

export const useDeleteJob = () => {
	const queryClient = useQueryClient();
	const {supabase} = useSupabase();

	const {show: showErrorToast} = useToast({
		accent: 'danger',
		accentPosition: 'left',
		description: 'Job could not be deleted. Please try again.',
		duration: 1500,
		title: 'Job Deletion Failed',
	});

	return useMutation({
		mutationFn: async ({id}: {id: number}) => {
			const {data, error} = await supabase.from('jobs').delete().match({id}).maybeSingle<Job>();
			if (error) throw error;
			return data;
		},
		onMutate: async ({id}) => {
			await queryClient.cancelQueries({queryKey: [QUERY_KEYS.Jobs, QUERY_KEYS.UnfilteredJobs]});
			const previousJobs = queryClient.getQueryData<Job[]>(QUERY_KEYS.Jobs) ?? [];
			const previousUnfilteredJobs = queryClient.getQueryData<Job[]>(QUERY_KEYS.UnfilteredJobs) ?? [];

			queryClient.setQueryData(
				QUERY_KEYS.Jobs,
				previousJobs.filter(job => job.id !== id),
			);

			queryClient.setQueryData(
				QUERY_KEYS.UnfilteredJobs,
				previousUnfilteredJobs.filter(job => job.id !== id),
			);

			return {previousJobs, previousUnfilteredJobs};
		},
		onError: (_error, _variables, context) => {
			queryClient.setQueryData(QUERY_KEYS.Jobs, context?.previousJobs);
			queryClient.setQueryData(QUERY_KEYS.UnfilteredJobs, context?.previousUnfilteredJobs);
			showErrorToast();
		},
		onSettled: async () => {
			await queryClient.invalidateQueries({queryKey: QUERY_KEYS.UnfilteredJobs});
			await queryClient.invalidateQueries({queryKey: QUERY_KEYS.Jobs});
		},
	});
};

export const useUpsertJob = () => {
	const queryClient = useQueryClient();
	const {supabase} = useSupabase();

	const {show: showErrorToast} = useToast({
		accent: 'danger',
		accentPosition: 'left',
		description: 'Job could not be updated. Please try again.',
		duration: 1500,
		title: 'Job Update Failed',
	});

	return useMutation({
		mutationFn: async (
			job: Omit<SetRequired<Partial<Job>, 'remote' | 'status' | 'title'>, 'company'> & {
				company?: number | null;
			},
		) => {
			const jobSearch = queryClient.getQueryData<JobSearch>(QUERY_KEYS.ActiveJobSearch);

			const {data, error} = await supabase
				.from('jobs')
				.upsert(
					{
						...job,
						job_search: jobSearch?.id,
						exit_status: job.status === JOB_STATUS.Closed ? job.exit_status : null,
						applied_at:
							job.status === JOB_STATUS.ApplicationSubmitted && !job.applied_at
								? new Date().toISOString()
								: job.applied_at,
					},
					{onConflict: 'id'},
				)
				.select('*, company(*), job_search!inner(is_active)')
				.maybeSingle<Job>();
			if (error) throw error;

			return data;
		},
		onMutate: async updatedJob => {
			await queryClient.cancelQueries({queryKey: [QUERY_KEYS.Jobs, QUERY_KEYS.UnfilteredJobs]});
			const previousJobs = queryClient.getQueryData<Job[]>(QUERY_KEYS.Jobs) ?? [];
			const previousUnfilteredJobs = queryClient.getQueryData<Job[]>(QUERY_KEYS.UnfilteredJobs) ?? [];
			const companies = queryClient.getQueryData<Company[]>(QUERY_KEYS.Companies) ?? [];

			const job = previousJobs.find(job => job.id === updatedJob.id);
			const company = companies.find(company => company.id === updatedJob.company);
			if (!company) return;

			const updatedJobs = job
				? previousUnfilteredJobs.map(job =>
						job.id === updatedJob.id ? {...job, ...updatedJob, company} : job,
					)
				: [...previousUnfilteredJobs, {...updatedJob, company, id: -1} as Job];

			queryClient.setQueryData(QUERY_KEYS.Jobs, updatedJobs);
			queryClient.setQueryData(QUERY_KEYS.UnfilteredJobs, updatedJobs);

			return {previousJobs, previousUnfilteredJobs};
		},
		onError: (_error, {id}, context) => {
			queryClient.setQueryData(QUERY_KEYS.Jobs, context?.previousJobs);
			queryClient.setQueryData(QUERY_KEYS.UnfilteredJobs, context?.previousUnfilteredJobs);
			showErrorToast(
				id
					? undefined
					: {title: 'Job Creation Failed', description: 'Job could not be added. Please try again.'},
			);
		},
		onSettled: async () => {
			await queryClient.invalidateQueries({queryKey: QUERY_KEYS.UnfilteredJobs});
			await queryClient.invalidateQueries({queryKey: QUERY_KEYS.Jobs});
		},
	});
};
