import {type ReactNode, useCallback} from 'react';
import {UpsertCompanyForm} from '~/shared/components/forms/company/upsert';
import {useUpsertCompany} from '~/shared/data/company';
import type {UpsertCompanyFormSchema} from '~/shared/schemas/company';
import {Dialog, Overlay} from '@job-ish/ui/components';

import type {Company} from '@job-ish/database/types';
import type {SubmitHandler} from 'react-hook-form';

export type CompanyDialogProps = {
	trigger?: ReactNode;
	open?: boolean;
	onCompanyAdded?: (id?: number) => void;
	onOpenChange?: (open: boolean) => void;
	company?: Company;
};

export const CompanyDialog = ({trigger, open, onOpenChange, onCompanyAdded, company}: CompanyDialogProps) => {
	const {mutate: upsertCompany, isPending} = useUpsertCompany();

	const handleCompanyUpsert: SubmitHandler<UpsertCompanyFormSchema> = useCallback(
		async formData => {
			upsertCompany(
				{...formData, id: company?.id},
				{onSuccess: data => onCompanyAdded?.(data?.id), onSettled: () => onOpenChange?.(false)},
			);
		},
		[company?.id, onCompanyAdded, onOpenChange, upsertCompany],
	);

	return (
		<Dialog onOpenChange={onOpenChange} open={open}>
			<Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
			<Dialog.Content>
				{isPending && <Overlay loading />}
				<Dialog.Title>{company ? 'Update' : 'Add'} Company</Dialog.Title>
				{!company && (
					<Dialog.Description>
						You can use this form to add any company that is not already present in the system.
					</Dialog.Description>
				)}
				<UpsertCompanyForm company={company} onSubmit={handleCompanyUpsert} />
			</Dialog.Content>
		</Dialog>
	);
};
