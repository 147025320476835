import {Fragment, useCallback, useEffect, useMemo} from 'react';
import {CompanySelect} from '~/shared/components/inputs/company-select';
import {FormInput} from '~/shared/components/inputs/form-input';
import {JobExitStatusSelect} from '~/shared/components/inputs/job-exit-status-select';
import {JobStatusSelect} from '~/shared/components/inputs/job-status-select';
import {jobTypeGroups, jobTypes} from '~/shared/constants/job';
import type {UpsertJobFormSchema} from '~/shared/schemas/job';
import {
	ButtonGroup,
	FormControl,
	IconButton,
	Label,
	NumberField,
	RadioGroup,
	Select,
	TextField,
} from '@job-ish/ui/components';
import {isValidUrl} from '@job-ish/utilities/url';
import {
	IconBriefcase,
	IconCash,
	IconExternalLink,
	IconHierarchy2,
	IconLink,
	IconMapPin,
} from '@tabler/icons-react';
import clsx from 'clsx';
import omit from 'lodash.omit';

import type {useForm} from 'react-hook-form';
import type {SetRequired} from 'type-fest';

type UpsertJobFormDetailsProps = {
	payType: 'salary' | 'hourly';
	setPayType: (payType: 'salary' | 'hourly') => void;
} & SetRequired<Partial<ReturnType<typeof useForm<UpsertJobFormSchema>>>, 'watch' | 'control' | 'setValue'>;

export const UpsertJobFormDetails = ({
	payType,
	setPayType,
	control,
	watch,
	setValue,
}: UpsertJobFormDetailsProps) => {
	const [minHourlyRate, maxHourlyRate, minSalary, maxSalary] = watch([
		'min_hourly_rate',
		'max_hourly_rate',
		'min_salary',
		'max_salary',
	]);

	const currency = useMemo(() => watch('currency') || 'USD', [watch]);

	useEffect(() => {
		if (minHourlyRate || maxHourlyRate) {
			setValue('max_salary', null);
			setValue('min_salary', null);
		}
	}, [minHourlyRate, maxHourlyRate, setValue]);

	useEffect(() => {
		if (minSalary || maxSalary) {
			setValue('max_hourly_rate', null);
			setValue('min_hourly_rate', null);
		}
	}, [minSalary, maxSalary, setValue]);

	const jobTypesForGroup = useCallback((group: string) => jobTypes.filter(type => type.group === group), []);
	const jobTypeLabel = useCallback((type?: string) => jobTypes.find(t => t.value === type)?.label, []);

	return (
		<div className="mb-1 grid grid-cols-2 gap-3">
			<FormInput
				className="col-span-2 sm:col-span-1"
				control={control}
				label="Job Title"
				name="title"
				render={field => (
					<TextField
						{...field}
						placeholder="Enter job title"
						prefix={<IconHierarchy2 className="h-4 w-4" />}
					/>
				)}
				required
			/>

			<div className="relative col-span-2 sm:col-span-1">
				{watch('status') === 'closed' && (
					<FormInput
						className="absolute -top-0.5 right-0"
						control={control}
						name="exit_status"
						render={field => (
							<JobExitStatusSelect
								onValueChange={value =>
									value && setValue('exit_status', value, {shouldDirty: true, shouldValidate: true})
								}
								value={field.value}
							/>
						)}
						required
					/>
				)}
				<FormInput
					className="w-full"
					control={control}
					label="Status"
					name="status"
					render={field => (
						<JobStatusSelect
							{...field}
							onValueChange={value =>
								value && setValue('status', value, {shouldDirty: true, shouldValidate: true})
							}
						/>
					)}
					required
				/>
			</div>
			<FormInput
				className="col-span-2 sm:col-span-1"
				control={control}
				label="Listing URL"
				name="url"
				render={field => (
					<ButtonGroup className="w-full">
						<TextField
							{...field}
							className={clsx(isValidUrl(field.value) ? 'rounded-r-none' : '', 'flex-grow')}
							placeholder="Enter job listing URL"
							prefix={<IconLink className="h-4 w-4" />}
							value={field.value || undefined}
						/>
						{isValidUrl(field.value) && (
							<IconButton
								icon={IconExternalLink}
								onPress={() => window.open(field.value, '_blank')}
								rel="noopener noreferrer"
								target="_blank"
							/>
						)}
					</ButtonGroup>
				)}
			/>

			<FormInput
				className="col-span-2 sm:col-span-1"
				control={control}
				label="Company"
				name="company"
				render={field => (
					<CompanySelect
						{...omit(field, 'ref')}
						onValueChange={value =>
							setValue('company', value as UpsertJobFormSchema['company'], {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
						value={field.value || undefined}
					/>
				)}
			/>

			<FormControl label="Pay">
				<RadioGroup
					className="w-fit"
					onValueChange={value => setPayType(value as 'salary' | 'hourly')}
					orientation="horizontal"
					value={payType}
				>
					<Label inline>
						Salary
						<RadioGroup.Item value="salary" />
					</Label>
					<Label inline>
						Hourly
						<RadioGroup.Item value="hourly" />
					</Label>
				</RadioGroup>
			</FormControl>

			<div className="col-span-2 flex items-center gap-3 sm:col-span-1">
				{payType === 'hourly' ? (
					<Fragment key="hourly_rate">
						<FormInput
							className="grow"
							control={control}
							label="Min. Hourly Rate"
							name="min_hourly_rate"
							render={field => (
								<NumberField
									{...field}
									className="[&>input]:w-0"
									formatOptions={{style: 'currency', currency}}
									placeholder="Enter min"
									prefix={<IconCash className="h-4 w-4" />}
									step={0.05}
									value={field.value || undefined}
								/>
							)}
						/>

						<FormInput
							className="grow"
							control={control}
							label="Max. Hourly Rate"
							name="max_hourly_rate"
							render={field => (
								<NumberField
									{...field}
									className="[&>input]:w-0"
									formatOptions={{style: 'currency', currency}}
									placeholder="Enter max"
									prefix={<IconCash className="h-4 w-4" />}
									step={0.05}
									value={field.value || undefined}
								/>
							)}
						/>
					</Fragment>
				) : (
					<>
						<FormInput
							className="grow"
							control={control}
							label="Min. Salary"
							name="min_salary"
							render={field => (
								<NumberField
									{...field}
									className="[&>input]:w-0"
									formatOptions={{style: 'currency', currency, maximumFractionDigits: 0}}
									minValue={0}
									placeholder="Enter min"
									prefix={<IconCash className="h-4 w-4" />}
									step={500}
									value={field.value || undefined}
								/>
							)}
						/>

						<FormInput
							className="grow"
							control={control}
							label="Max. Salary"
							name="max_salary"
							render={field => (
								<NumberField
									{...field}
									className="[&>input]:w-0"
									formatOptions={{style: 'currency', currency, maximumFractionDigits: 0}}
									minValue={0}
									placeholder="Enter max"
									prefix={<IconCash className="h-4 w-4" />}
									step={500}
									value={field.value || undefined}
								/>
							)}
						/>
					</>
				)}
			</div>

			<FormInput
				className="col-span-2 sm:col-span-1"
				control={control}
				label="Work Arrangement"
				name="remote"
				render={field => (
					<RadioGroup
						{...field}
						className="w-full"
						onChange={undefined}
						onValueChange={value =>
							setValue('remote', value as UpsertJobFormSchema['remote'], {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
						orientation="horizontal"
						value={field.value}
					>
						<Label inline>
							Remote
							<RadioGroup.Item value="true" />
						</Label>
						<Label inline>
							Onsite
							<RadioGroup.Item value="false" />
						</Label>
						<Label inline>
							Hybrid
							<RadioGroup.Item value="hybrid" />
						</Label>
					</RadioGroup>
				)}
				required
			/>

			<FormInput
				className="col-span-2 sm:col-span-1"
				control={control}
				label="Location"
				name="location"
				render={field => (
					<TextField
						onChange={value => setValue('location', value, {shouldDirty: true, shouldValidate: true})}
						placeholder="Enter location"
						prefix={<IconMapPin className="h-4 w-4" />}
						value={field.value}
					/>
				)}
			/>

			<FormInput
				className="col-span-2 sm:col-span-1"
				control={control}
				label="Job Type"
				name="type"
				render={field => (
					<Select
						onValueChange={value =>
							setValue('type', value ? (value as UpsertJobFormSchema['type']) : null, {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
						value={field.value || undefined}
					>
						<Select.Trigger
							{...field}
							className="w-full"
							clearable
							placeholder="Select job type"
							prefix={<IconBriefcase className="h-4 w-4" />}
						>
							{jobTypeLabel(field.value || undefined)}
						</Select.Trigger>
						<Select.Content className="z-50 w-64">
							{jobTypeGroups.map((group, index) => (
								<Select.Group key={group.label}>
									<Select.Label>{group.label}</Select.Label>
									{jobTypesForGroup(group.value).map(type => (
										<Select.Item key={type.value} value={type.value}>
											{type.label}
										</Select.Item>
									))}
									{index !== jobTypeGroups.length - 1 && <Select.Separator />}
								</Select.Group>
							))}
						</Select.Content>
					</Select>
				)}
			/>
		</div>
	);
};
