import {useRef, useState} from 'react';
import {IconBuildingSkyscraper} from '@tabler/icons-react';

import type {Company} from '@job-ish/database/types';
import type {SetRequired} from 'type-fest';

type CompanyLogoProps = {
	company?: SetRequired<Partial<Company>, 'name'> | null;
};

export const CompanyLogo = ({company}: CompanyLogoProps) => {
	const [error, setError] = useState(false);
	const imgRef = useRef<HTMLImageElement>(null);

	return (company?.logo || company?.website) && !error ? (
		<img
			alt={`${company.name} logo`}
			className="h-4 w-4"
			height={20}
			onError={() => setError(true)}
			ref={imgRef}
			referrerPolicy="no-referrer"
			src={company.logo || `https://www.google.com/s2/favicons?domain=${company.website}&sz=256`}
			width={20}
		/>
	) : (
		<IconBuildingSkyscraper className="h-4 w-4" />
	);
};
