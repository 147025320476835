import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {useNumberFieldState} from 'react-stately';
import {useLocale} from '@react-aria/i18n';
import {useNumberField} from '@react-aria/numberfield';
import {IconChevronDown, IconChevronUp, IconX} from '@tabler/icons-react';
import omit from 'lodash.omit';
import {twMerge} from 'tailwind-merge';

import type {AriaNumberFieldProps} from '@react-aria/numberfield';
import type {ReactNode} from 'react';
import {FormControlContext} from '../form-control';
import IconButton from '../icon-button';
import {textFieldInputStyles, textFieldStyles} from '../text-field/styles';
import type {TextFieldVariantProps} from '../text-field/styles';

export type NumberFieldProps = Omit<AriaNumberFieldProps, 'isDisabled' | 'isReadOnly' | 'isRequired'> &
	Omit<TextFieldVariantProps, 'textarea' | 'invalid'> & {
		className?: string;
		prefix?: ReactNode;
		suffix?: ReactNode;
		required?: boolean;
		clearable?: boolean;
	};

const NumberField = forwardRef<HTMLInputElement, NumberFieldProps>(
	(
		{
			size,
			subtle,
			hidden,
			disabled,
			readonly,
			required,
			value,
			onChange,
			suffix,
			className,
			prefix,
			clearable,
			...props
		},
		forwardedRef,
	) => {
		const formControlProps = useContext(FormControlContext);
		const {locale} = useLocale();
		const ref = useRef<HTMLInputElement>(null);
		const state = useNumberFieldState({
			...props,
			isDisabled: !!disabled,
			isReadOnly: !!readonly,
			isRequired: required,
			value,
			onChange,
			locale,
		});
		const {inputProps, incrementButtonProps, decrementButtonProps, groupProps} = useNumberField(
			{
				...props,
				value,
				onChange,
				isDisabled: !!disabled,
				isReadOnly: !!readonly,
				isRequired: required,
				'aria-labelledby': formControlProps.id,
				id: `${formControlProps.id}-field`,
			},
			state,
			ref,
		);

		useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement);

		return (
			<div
				{...groupProps}
				className={twMerge(
					textFieldStyles({size, subtle, hidden, invalid: formControlProps.invalid, textarea: false}),
					className,
				)}
			>
				{prefix && <div className="flex shrink-0 flex-nowrap items-center">{prefix}</div>}
				<input {...inputProps} className={textFieldInputStyles()} ref={ref} />
				{clearable && !disabled && !readonly && !!value && (
					<IconButton as="div" circular icon={IconX} onPress={() => onChange?.(0)} size="xs" />
				)}
				<div className="relative flex flex-col px-0.5">
					<div className="absolute -right-0.5 bottom-0">
						<IconButton
							{...omit(incrementButtonProps, ['isDisabled', 'allowFocusWhenDisabled', 'preventFocusOnPress'])}
							className="min-h-0 p-0"
							disabled={incrementButtonProps.isDisabled}
							icon={IconChevronUp}
							intent="ghost"
							size="xs"
						/>
					</div>
					<div className="absolute -right-0.5 top-0">
						<IconButton
							{...omit(decrementButtonProps, ['isDisabled', 'allowFocusWhenDisabled', 'preventFocusOnPress'])}
							className="min-h-0 p-0"
							disabled={decrementButtonProps.isDisabled}
							icon={IconChevronDown}
							intent="ghost"
							size="xs"
						/>
					</div>
				</div>
				{suffix && <div className="flex shrink-0 flex-nowrap items-center text-sm">{suffix}</div>}
			</div>
		);
	},
);

NumberField.displayName = 'NumberField';

export default NumberField;
