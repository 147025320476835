import {useMemo} from 'react';
import type {JobExit, JobStatusGroup} from '~/shared/constants/job';
import {JOB_STATUS, jobExits, jobStatusGroups} from '~/shared/constants/job';
import {useJobStatuses} from '~/shared/hooks/use-job-status';
import {Badge, DropdownMenu, Indicator} from '@job-ish/ui/components';

import type {BadgeProps} from '@job-ish/ui/components';

type JobExitStatusSelectProps = {
	value?: JobExit | null;
	onValueChange: (value?: JobExit) => void;
	disabled?: boolean;
};

export const JobExitStatusSelect = ({value, onValueChange, disabled}: JobExitStatusSelectProps) => {
	const jobExitGroups = useMemo(
		() => Object.entries(jobStatusGroups).filter(([key]) => key !== JOB_STATUS.Closed),
		[],
	);

	const selectedExit = useMemo(() => (value ? jobExits[value] : null), [value]);

	const {exitsForGroup} = useJobStatuses();

	return (
		<DropdownMenu>
			<DropdownMenu.Trigger asChild>
				<Badge
					className="relative"
					color={(selectedExit?.color ?? 'neutral') as BadgeProps['color']}
					disabled={disabled}
					interactive
					size="sm"
				>
					<span>{selectedExit?.label ?? 'Select Exit Status'}</span>
					{!selectedExit && (
						<Indicator className="absolute -right-0.5 -top-0.5" color="info" pulse size="xs" />
					)}
				</Badge>
			</DropdownMenu.Trigger>
			<DropdownMenu.Content align="end" className="z-50">
				<DropdownMenu.RadioGroup
					onValueChange={value => onValueChange(value as Parameters<typeof onValueChange>[0])}
					value={value ?? undefined}
				>
					{jobExitGroups.map(([groupKey, group], index) => (
						<DropdownMenu.Group key={groupKey}>
							<DropdownMenu.Label>{group.label}</DropdownMenu.Label>
							{exitsForGroup(groupKey as JobStatusGroup).map(([statusKey, status]) => (
								<DropdownMenu.RadioItem key={statusKey} value={statusKey}>
									{status.label}
								</DropdownMenu.RadioItem>
							))}
							{index < jobExitGroups.length - 1 && <DropdownMenu.Separator />}
						</DropdownMenu.Group>
					))}
				</DropdownMenu.RadioGroup>
			</DropdownMenu.Content>
		</DropdownMenu>
	);
};
