import {jobExits, jobStatuses} from '~/shared/constants/job';
import Fuse from 'fuse.js';
import uniqBy from 'lodash.uniqby';

import type {Job, OperationFilter} from '@job-ish/database/types';
import type {FuseOptionKey} from 'fuse.js';

export const getUniqueFilterValues = (key: keyof OperationFilter, jobs: Job[]) => {
	if (key === 'status') return Object.keys(jobStatuses);
	else if (key === 'exit_status') return Object.keys(jobExits);
	return uniqBy(jobs, key)
		.map(job => key.split('.').reduce<unknown>((acc, current) => acc?.[current as keyof typeof acc], job))
		.filter(value => !!value) as string[];
};

export const applySearchFilters = <T extends Record<string, unknown>>(
	records?: T[],
	keys?: FuseOptionKey<T>[],
	searchText?: string | null,
) =>
	searchText && records
		? new Fuse(records, {findAllMatches: true, keys}).search(searchText).map(({item}) => item)
		: (records ?? []);
