import type {Readable} from 'node:stream';

export const postData = async <T>({
	url,
	data,
	method = 'POST',
}: {
	url: string | URL | Request;
} & (
	| {data?: {[key: string]: unknown}; method?: 'POST' | 'PUT' | 'DELETE' | 'PATCH'}
	| {data?: never; method: 'GET'}
)): Promise<T | null> => {
	const res: Response = await fetch(url, {
		method,
		headers: new Headers({
			'Content-Type': 'application/json',
			Cookie:
				typeof window === 'undefined'
					? await import('next/headers').then(({cookies}) => cookies().toString())
					: document.cookie,
		}),
		credentials: 'same-origin',
		body: JSON.stringify(data),
	});

	if (!res.ok) {
		return null;
	}

	return res.json();
};

export const getRawBody = async (readable: Readable) => {
	const chunks = [];
	for await (const chunk of readable) {
		chunks.push(typeof chunk === 'string' ? Buffer.from(chunk) : chunk);
	}
	return Buffer.concat(chunks);
};
