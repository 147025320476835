import {useForm} from 'react-hook-form';
import {FormInput} from '~/shared/components/inputs/form-input';
import type {UpsertCompanyFormSchema} from '~/shared/schemas/company';
import {getDefaultUpsertCompanyFormValues, upsertCompanySchema} from '~/shared/schemas/company';
import {zodResolver} from '@hookform/resolvers/zod';
import {Button, TextButton, TextField} from '@job-ish/ui/components';
import {IconBuildingSkyscraper, IconWorldWww} from '@tabler/icons-react';

import type {Company} from '@job-ish/database/types';
import type {FormEventHandler} from 'react';
import type {SubmitHandler} from 'react-hook-form';

type UpsertCompanyFormProps = {
	company?: Company;
	onSubmit: SubmitHandler<UpsertCompanyFormSchema>;
};

export const UpsertCompanyForm = ({company, onSubmit}: UpsertCompanyFormProps) => {
	const {control, handleSubmit, reset} = useForm<UpsertCompanyFormSchema>({
		resolver: zodResolver(upsertCompanySchema),
		defaultValues: getDefaultUpsertCompanyFormValues(company),
	});

	const submitForm: FormEventHandler<HTMLFormElement> = async event => {
		event.preventDefault();
		event.stopPropagation();
		await handleSubmit(onSubmit)();
	};

	const resetForm: FormEventHandler<HTMLFormElement> = event => {
		event.preventDefault();
		event.stopPropagation();
		reset();
	};

	return (
		<form
			className="flex h-full w-full grow flex-col overflow-hidden"
			onReset={resetForm}
			onSubmit={submitForm}
		>
			<div className="flex h-full max-h-full w-full flex-col gap-4 overflow-y-auto p-2">
				<div className="flex h-fit grow flex-col gap-2">
					<FormInput
						control={control}
						label="Company Name"
						name="name"
						render={field => (
							<TextField
								{...field}
								placeholder="Enter company name"
								prefix={<IconBuildingSkyscraper className="h-4 w-4" />}
							/>
						)}
						required
					/>

					<FormInput
						control={control}
						label="Company Website"
						name="website"
						render={field => (
							<TextField
								{...field}
								defaultValue={field.value}
								placeholder="Enter company website"
								prefix={<IconWorldWww className="h-4 w-4" />}
								value={undefined}
							/>
						)}
						required
					/>
				</div>
			</div>
			<div className="mt-auto flex items-center justify-end gap-3 px-2 pt-4">
				{company && <TextButton type="reset">Reset</TextButton>}
				<Button color="primary" type="submit">
					{company ? 'Update' : 'Add'} Company
				</Button>
			</div>
		</form>
	);
};
