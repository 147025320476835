import {FormInput} from '~/shared/components/inputs/form-input';
import type {UpsertJobFormSchema} from '~/shared/schemas/job';
import {convertToCalendarDateTime, convertToUnix} from '~/shared/utils/date';
import {convertToTimestampz} from '~/shared/utils/time';
import {DatePicker, FormControl} from '@job-ish/ui/components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import type {Job} from '@job-ish/database/types';
import type {useForm} from 'react-hook-form';
import type {SetRequired} from 'type-fest';

dayjs.extend(utc);

type UpsertJobFormDatesProps = {
	job?: Job;
} & SetRequired<Partial<ReturnType<typeof useForm<UpsertJobFormSchema>>>, 'control' | 'setValue'>;

export const UpsertJobFormDates = ({job, control, setValue}: UpsertJobFormDatesProps) => (
	<div className="mb-1 flex h-full w-full flex-col gap-8">
		<div className="grid grid-cols-2 gap-3">
			{job?.created_at && (
				<FormControl className="col-span-2 sm:col-span-1" label="Date Created">
					<DatePicker granularity="day" readonly value={convertToCalendarDateTime(job.created_at)} />
				</FormControl>
			)}

			<FormInput
				className="col-span-2 sm:col-span-1"
				control={control}
				label="Date Applied"
				name="applied_at"
				render={field => (
					<DatePicker
						clearable
						granularity="day"
						key={`${field.value === null}`}
						onChange={value =>
							setValue('applied_at', value ? convertToTimestampz(convertToUnix(value)) : null, {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
						value={field.value ? convertToCalendarDateTime(field.value) : undefined}
					/>
				)}
			/>
		</div>
	</div>
);
