import type {JobExit, JobStatus, JobStatusGroup} from '~/shared/constants/job';
import {jobStatuses} from '~/shared/constants/job';

import type {Job, OperationFilter} from '@job-ish/database/types';
import {applySearchFilters} from './operations';

export const getJobStatusesForGroup = (statusGroup: JobStatusGroup, jobs: Job[] = []) =>
	Object.entries(jobStatuses).filter(
		([key, jobStatus]) =>
			jobStatus.group === statusGroup && (jobs.length === 0 || jobs.some(job => job.status === key)),
	);

export const getJobsForStatusGroup = (jobs: Job[] = [], statusGroup: JobStatusGroup) =>
	jobs.filter(job => getJobStatusesForGroup(statusGroup).some(([key]) => key === job.status));

export const getJobsForStatus = (jobs: Job[] = [], status: JobStatus) =>
	jobs.filter(job => job.status === status);

export const shouldShowJob = (job: Job, filter?: OperationFilter) =>
	!filter ||
	Object.entries(filter).every(([key, value]) => {
		if (typeof value === 'boolean') {
			return job[key as keyof Job] === value;
		} else if (Array.isArray(value)) {
			const columnValue = key.includes('.')
				? key.split('.').reduce<unknown>((acc, current) => acc?.[current as keyof typeof acc], job)
				: job[key as keyof Job];

			return columnValue
				? (value.map(v => v?.toString()) as string[]).includes(columnValue.toString())
				: true;
		}
		return true;
	});

export const sortJobs = (jobs: Job[]) =>
	jobs.sort((a, b) => {
		if (a.index !== null && b.index !== null) return a.index - b.index;
		else if (a.index !== null) return 1;
		else if (b.index !== null) return -1;
		return b.id - a.id;
	});

export const getFilteredJobs = (jobs: Job[], filter?: OperationFilter, searchText?: string | null) =>
	sortJobs(
		applySearchFilters(
			jobs.filter(job => shouldShowJob(job, filter)),
			['title', 'company.name', 'location', 'labels', 'notes'],
			searchText,
		),
	);

export const getJobsMatchingStatuses = (
	jobs: Job[],
	{neq, eq}: {neq?: (JobStatus | JobExit)[]; eq?: (JobStatus | JobExit)[]},
) => {
	const neqFilter = (job: Job) =>
		neq && !neq.includes(job.status) && (!job.exit_status || !neq.includes(job.exit_status));
	const eqFilter = (job: Job) =>
		eq && (eq.includes(job.status) || (job.exit_status && eq.includes(job.exit_status)));
	return jobs.filter(job => neqFilter(job) || eqFilter(job));
};
