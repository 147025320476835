import {useCallback, useEffect, useMemo, useState} from 'react';
import type {CoverLetterDialogProps} from '~/shared/components/dialogs/cover-letter';
import {CoverLetterDialog} from '~/shared/components/dialogs/cover-letter';
import {BadgeInput} from '~/shared/components/inputs/badge-input';
import {FormInput} from '~/shared/components/inputs/form-input';
import {ResumeSelect} from '~/shared/components/inputs/resume-select';
import {useCompanies} from '~/shared/data/company';
import type {UpsertJobFormSchema} from '~/shared/schemas/job';
import {Button, CopyButton, Empty, IconButton, Overlay, RichText, Separator} from '@job-ish/ui/components';
import {useModal} from '@job-ish/ui/hooks';
import {IconFileTextAi, IconRefresh} from '@tabler/icons-react';
import {useCompletion} from 'ai/react';

import type {RequestOptions} from 'ai';
import type {useForm} from 'react-hook-form';
import type {SetRequired} from 'type-fest';

type UpsertJobFormResourcesProps = SetRequired<
	Partial<ReturnType<typeof useForm<UpsertJobFormSchema>>>,
	'control' | 'setValue' | 'watch'
> & {id?: number};

export const UpsertJobFormResources = ({id, control, setValue, watch}: UpsertJobFormResourcesProps) => {
	const {show: showDialog} = useModal();
	const {completion, complete, isLoading} = useCompletion({api: '/api/generate/cover-letter'});

	const handleComplete = useCallback(
		async (options?: RequestOptions, companyId?: number) => {
			if (companyId) {
				setValue('company', companyId, {shouldDirty: true, shouldValidate: true});
			}
			return await complete('', options);
		},
		[complete, setValue],
	);

	const [markdownCoverLetter, setMarkdownCoverLetter] = useState('');

	const {data: companies} = useCompanies();
	const selectedCompany = useMemo(
		() => companies?.find(company => company.id === watch('company')),
		[companies, watch],
	);

	const coverLetter = watch('cover_letter');
	useEffect(() => {
		if (typeof window !== 'undefined') {
			import('turndown').then(({default: TurndownService}) => {
				if (coverLetter) {
					const turndownService = new TurndownService();
					setMarkdownCoverLetter(turndownService.turndown(coverLetter));
				}
			});
		}
	}, [coverLetter]);

	return (
		<div className="gap-3">
			<div className="grid grid-cols-2 gap-3">
				<FormInput
					className="col-span-2 sm:col-span-1"
					control={control}
					label="Labels"
					name="labels"
					render={field => (
						<BadgeInput
							onChange={value => setValue('labels', value, {shouldDirty: true, shouldValidate: true})}
							value={field.value}
						/>
					)}
				/>

				<FormInput
					className="col-span-2 min-w-0 sm:col-span-1"
					control={control}
					label="Resume"
					name="resume"
					render={field => (
						<ResumeSelect
							{...field}
							onValueChange={value => setValue('resume', value, {shouldDirty: true, shouldValidate: true})}
							value={field.value || undefined}
						/>
					)}
				/>

				{watch('cover_letter') || completion || isLoading ? (
					<FormInput
						className="relative col-span-2 min-w-0"
						control={control}
						label="Cover Letter"
						name="cover_letter"
						render={field => (
							<div className="relative">
								<RichText
									className="relative min-h-[24rem]"
									content={field.value}
									onContentChange={value =>
										setValue('cover_letter', value, {shouldDirty: true, shouldValidate: true})
									}
									options={{editable: !isLoading}}
									placeholder="Add cover letter"
									toolbarSuffix={
										<div className="flex grow items-center justify-end">
											<IconButton
												as="div"
												className="rounded-none"
												icon={IconRefresh}
												intent="ghost"
												onPress={() =>
													showDialog<CoverLetterDialogProps>(CoverLetterDialog, {
														id,
														title: watch('title'),
														company: selectedCompany,
														complete: handleComplete,
														job_description: watch('job_description') || null,
														resume: watch('resume') || null,
													})
												}
												role="button"
												size="sm"
											/>
											<Separator className="h-5" orientation="vertical" />
											{field.value && (
												<CopyButton
													as="div"
													className="rounded-none hover:bg-transparent"
													iconOnly
													intent="ghost"
													role="button"
													size="sm"
													text={markdownCoverLetter}
												/>
											)}
										</div>
									}
									uncontrolledContent={completion}
								/>
								{isLoading && <Overlay loading />}
							</div>
						)}
					/>
				) : (
					<Empty
						className="col-span-2 mt-8 min-w-0"
						icon={IconFileTextAi}
						size="lg"
						subtitle="Provide some details about the role to generate a cover letter."
						title="Generate Cover Letter"
					>
						<Button
							color="primary"
							onPress={() =>
								showDialog<CoverLetterDialogProps>(CoverLetterDialog, {
									id,
									title: watch('title'),
									company: selectedCompany,
									complete: handleComplete,
									job_description: watch('job_description') || null,
									resume: watch('resume') || null,
								})
							}
						>
							Get Started
						</Button>
					</Empty>
				)}
			</div>
		</div>
	);
};
