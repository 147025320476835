import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const calendarCellStyles = cva(
	[
		'flex flex-col-reverse items-center min-h-full min-w-full relative',
		'font-medium rounded-md focus-visible:outline focus-visible:outline-mauve8',
	],
	{
		variants: {
			today: {true: 'bg-violet3'},
			unavailable: {true: 'opacity-40 pointer-events-none text-red11'},
			selected: {true: 'bg-violet9 dark:text-mauve12 text-mauve1'},
			disabled: {true: 'opacity-40 pointer-events-none'},
			hidden: {true: 'text-mauve11 pointer-events-none bg-mauve2'},
			withinSelectedRange: {true: 'bg-violet5'},
			selectedRangeLimit: {true: 'bg-mauve7'},
			compact: {true: 'justify-between h-full min-h-full', false: 'justify-center h-8 w-8'},
		},
		compoundVariants: [
			{
				selected: false,
				hidden: false,
				unavailable: false,
				today: false,
				className: 'text-mauve12 bg-mauve3 hover:bg-mauve4',
			},
			{
				selected: false,
				hidden: false,
				unavailable: false,
				today: true,
				className: 'text-mauve12 bg-violet6 hover:bg-violet7',
			},
		],
		defaultVariants: {unavailable: false, selected: false, disabled: false, compact: false},
	},
);

export type CalendarCellVariantProps = VariantProps<typeof calendarCellStyles>;
