import type {Job} from '@job-ish/database/types';
import type {Database} from '@job-ish/database/types/supabase';
import type {SupabaseClient} from '@supabase/supabase-js';

export const fetchJobs = async (supabase: SupabaseClient<Database>) => {
	const response = await supabase
		.from('jobs')
		.select('*, company(*), job_search!inner(is_active, id)')
		.eq('job_search.is_active', true)
		.returns<(Omit<Job, 'job_search'> & {job_search: {id: number; is_active: true}})[]>();

	return {...response, data: response.data?.map(job => ({...job, job_search: job.job_search.id})) ?? []};
};

export const fetchJob = async (supabase: SupabaseClient<Database>, job: Partial<Job>) => {
	const response = await supabase
		.from('jobs')
		.select('*, company(*), job_search!inner(is_active)')
		.match(job)
		.eq('job_search.is_active', true)
		.single<Job>();

	return {...response, data: response.data && {...response.data}};
};
