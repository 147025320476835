import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {useDatePickerState} from 'react-stately';
import {CalendarDateTime} from '@internationalized/date';
import {useDatePicker} from '@react-aria/datepicker';
import {IconCalendarEvent, IconClockEdit, IconX} from '@tabler/icons-react';
import omit from 'lodash.omit';
import {twMerge} from 'tailwind-merge';

import type {DateValue} from '@react-aria/calendar';
import type {TimeValue} from '@react-aria/datepicker';
import type {DatePickerStateOptions} from 'react-stately';
import {buttonIconStyles} from '../button/styles';
import Calendar from '../calendar';
import DateField from '../date-field';
import {FormControlContext} from '../form-control';
import IconButton from '../icon-button';
import Popover from '../popover';
import {textFieldStyles} from '../text-field/styles';
import TimeField from '../time-field';
import type {DateFieldProps} from '../date-field';

export type DatePickerProps = DateFieldProps & DatePickerStateOptions<DateValue>;
const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
	(
		{
			size,
			subtle,
			hidden,
			open,
			disabled,
			readonly,
			required,
			minValue,
			maxValue,
			clearable,
			granularity,
			hideTimeZone,
			hourCycle,
			className,
			...props
		},
		forwardedRef,
	) => {
		const formControlProps = useContext(FormControlContext);
		const state = useDatePickerState(props);
		const ref = useRef<HTMLInputElement>(null);
		const {groupProps, fieldProps, buttonProps, dialogProps, calendarProps} = useDatePicker(
			{
				...props,
				isOpen: open,
				isDisabled: !!disabled,
				isReadOnly: !!readonly,
				isRequired: required,
				granularity,
				hourCycle,
				hideTimeZone,
				minValue,
				maxValue,
				'aria-label': formControlProps.label,
				'aria-labelledby': formControlProps.id,
			},
			state,
			ref,
		);

		const handleTimeFieldChange = useCallback(
			(value: TimeValue | null) => {
				if (value) {
					state.setTimeValue(value);
					if (state.value) {
						props.onChange?.(
							new CalendarDateTime(
								state.value.year,
								state.value.month,
								state.value.day,
								value.hour,
								value.minute,
								value.second,
								value.millisecond,
							),
						);
					}
				}
			},
			[state, props],
		);

		const timeMinValue = minValue && 'hour' in minValue ? minValue : null;
		const timeMaxValue = maxValue && 'hour' in maxValue ? maxValue : null;

		useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement);

		return (
			<Popover {...dialogProps} onOpenChange={state.setOpen} open={state.isOpen}>
				<Popover.Anchor asChild>
					<div
						{...groupProps}
						className={twMerge(
							textFieldStyles({
								size,
								subtle,
								hidden,
								invalid: formControlProps.invalid,
								textarea: false,
								disabled: disabled,
								readonly: readonly,
							}),
							'w-full',
							className,
						)}
						ref={ref}
					>
						<DateField
							{...(fieldProps as DateFieldProps)}
							className="m-0 min-h-0 w-fit max-w-full truncate p-0"
							clearable={false}
							placeholder="Select a date"
							readonly
							subtle
						/>

						<div className="ml-auto flex items-center gap-2">
							{clearable && !disabled && !readonly && !!props.value && (
								<IconButton
									as="div"
									circular
									className="shrink-0"
									icon={IconX}
									onPress={() => props.onChange?.(null)}
									size="xs"
								/>
							)}
							<Popover.Trigger
								tabIndex={-1}
								{...omit(buttonProps, ['onPress', 'isDisabled'])}
								className="cursor-default focus-visible:outline-none"
								disabled={!!(disabled || readonly)}
							>
								<IconCalendarEvent
									className={twMerge(
										buttonIconStyles({size: 'sm'}),
										'relative m-0 flex shrink-0 flex-nowrap items-center p-0',
									)}
								/>
							</Popover.Trigger>
						</div>
					</div>
				</Popover.Anchor>
				<Popover.Content align="start" className="z-50">
					<Calendar
						{...omit(calendarProps, 'onChange')}
						footer={
							granularity !== 'day' && (
								<TimeField
									className="w-full"
									disabled={!calendarProps.value}
									granularity={granularity}
									hideTimeZone={hideTimeZone}
									hourCycle={hourCycle}
									key={`${!!calendarProps.value}`}
									maxValue={timeMaxValue || undefined}
									minValue={timeMinValue || undefined}
									onChange={handleTimeFieldChange}
									suffix={<IconClockEdit className="h-5 w-5" />}
									value={state.timeValue}
								/>
							)
						}
						onChange={state.setValue}
					/>
				</Popover.Content>
			</Popover>
		);
	},
);

export default DatePicker;
