import {forwardRef, useCallback, useState} from 'react';
import {useInterval} from 'react-use';
import {Badge, TextField} from '@job-ish/ui/components';
import {IconTag} from '@tabler/icons-react';

import type {KeyboardEvent} from 'react';

type BadgeInputProps = {
	value?: string[];
	onChange: (value: string[]) => void;
};

export const BadgeInput = forwardRef<HTMLInputElement, BadgeInputProps>(
	({value = [], onChange}: BadgeInputProps, forwardedRef) => {
		const [label, setLabel] = useState('');
		const [placeholder, setPlaceholder] = useState('Competitive benefits');

		useInterval(() => {
			switch (placeholder) {
				case 'Competitive benefits': {
					setPlaceholder('Rude recruiter');
					break;
				}
				case 'Rude recruiter': {
					setPlaceholder('Cool office');
					break;
				}
				case 'Cool office': {
					setPlaceholder('Competitive benefits');
					break;
				}
			}
		}, 5000);

		const handleAddLabel = useCallback(
			(label: string) => {
				if (label === '') return;
				if (!value.includes(label)) {
					onChange([...value, label]);
				}
				setLabel('');
			},
			[onChange, value],
		);

		const handleEnter = useCallback(
			(event: KeyboardEvent<HTMLInputElement>) => {
				event.preventDefault();
				handleAddLabel(label);
			},
			[handleAddLabel, label],
		);

		const handleDismiss = useCallback(
			(label: string) => {
				onChange(value.filter(value => value !== label));
			},
			[onChange, value],
		);

		return (
			<div className="flex w-full flex-col gap-1">
				<TextField
					onChange={setLabel}
					onKeyDown={event => event.key === 'Enter' && handleEnter(event)}
					placeholder={placeholder}
					prefix={<IconTag className="h-4 w-4" />}
					ref={forwardedRef}
					value={label}
				/>
				<div className="flex flex-wrap gap-1 overflow-hidden">
					{value.map(label => (
						<Badge
							className="max-w-full"
							dismissable
							key={label}
							onDismiss={() => handleDismiss(label)}
							size="sm"
						>
							<span className="truncate">{label}</span>
						</Badge>
					))}
				</div>
			</div>
		);
	},
);
