import {type FormEventHandler, useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {CompanySelect} from '~/shared/components/inputs/company-select';
import {FormInput} from '~/shared/components/inputs/form-input';
import {ResumeSelect} from '~/shared/components/inputs/resume-select';
import {useCompanies} from '~/shared/data/company';
import type {UpsertCoverLetterFormSchema} from '~/shared/schemas/cover-letter';
import {getDefaultUpsertCoverLetterFormValues, upsertCoverLetterSchema} from '~/shared/schemas/cover-letter';
import {zodResolver} from '@hookform/resolvers/zod';
import {Button, TextField} from '@job-ish/ui/components';
import {IconHierarchy2, IconInfoCircle, IconMailForward} from '@tabler/icons-react';
import omit from 'lodash.omit';

import type {SubmitHandler} from 'react-hook-form';

export type UpsertCoverLetterFormProps = {
	onSubmit: SubmitHandler<UpsertCoverLetterFormSchema>;
} & Parameters<typeof getDefaultUpsertCoverLetterFormValues>[0];

export const UpsertCoverLetterForm = ({onSubmit, company, ...props}: UpsertCoverLetterFormProps) => {
	const {control, handleSubmit, watch, setValue, getFieldState} = useForm<UpsertCoverLetterFormSchema>({
		resolver: zodResolver(upsertCoverLetterSchema),
		defaultValues: getDefaultUpsertCoverLetterFormValues({...props, company}),
		mode: 'onChange',
		reValidateMode: 'onChange',
	});
	const {data: companies} = useCompanies();

	const watchedCompany = watch('company');
	const selectedCompany = useMemo(
		() => companies?.find(c => c.id === watchedCompany),
		[companies, watchedCompany],
	);
	useEffect(() => {
		if (selectedCompany && !getFieldState('address_to').isDirty) {
			setValue('address_to', `${selectedCompany.name} Hiring Team`);
		}
	}, [getFieldState, selectedCompany, setValue]);

	const submitForm: FormEventHandler<HTMLFormElement> = async event => {
		event.preventDefault();
		event.stopPropagation();
		await handleSubmit(onSubmit)();
	};

	return (
		<form className="flex h-full w-full grow flex-col overflow-hidden" onSubmit={submitForm}>
			<div className="flex h-full max-h-full w-full flex-col gap-4 overflow-y-auto p-2">
				<div className="flex h-fit grow flex-col gap-2">
					<FormInput
						control={control}
						label="Job Title"
						name="job_title"
						render={field => (
							<TextField
								{...field}
								placeholder="Enter job title"
								prefix={<IconHierarchy2 className="h-4 w-4" />}
								readonly
							/>
						)}
						required
					/>

					<FormInput
						control={control}
						label="Company"
						name="company"
						render={field => (
							<CompanySelect
								{...omit(field, 'ref')}
								onValueChange={value => field.onChange(value)}
								readonly={!!company}
								value={field.value}
							/>
						)}
						required
					/>

					<FormInput
						control={control}
						label="Address To"
						name="address_to"
						render={field => (
							<TextField
								{...field}
								placeholder="Enter address to"
								prefix={<IconMailForward className="h-4 w-4" />}
							/>
						)}
						required
					/>

					<FormInput
						control={control}
						label="Resume"
						name="resume_id"
						render={field => (
							<ResumeSelect
								{...field}
								onValueChange={value =>
									setValue('resume_id', value, {shouldDirty: true, shouldValidate: true})
								}
								value={field.value || undefined}
							/>
						)}
					/>

					<FormInput
						control={control}
						label="Additional Details"
						name="additional_details"
						render={field => (
							<TextField
								{...field}
								inputElementType="textarea"
								placeholder="Enter additional details to include in the cover letter"
								rows={4}
							/>
						)}
					/>

					<div className="flex items-start text-pretty text-xs">
						<IconInfoCircle className="mr-1 mt-0.5 h-3 w-3 shrink-0" />
						<span>
							This feature is powered by AI and may not be perfect. Please review the generated cover letter
							before sending it to employers.
						</span>
					</div>
				</div>
			</div>
			<div className="mt-auto flex items-center justify-end gap-3 px-2 pt-4">
				<Button color="primary" type="submit">
					Generate
				</Button>
			</div>
		</form>
	);
};
